import * as React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import Boxed from "../components/Boxed";
import TextContent from "../components/TextContent";
import UserAgreementEN from "content/UserAgreementEN";

const PrivacyPolicy = () => {
  return <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>User Agreement and Privacy Policy | Paramla</title>
    </Helmet>
    <Boxed>
      <TextContent title="User Agreement and Privacy Policy">
        <UserAgreementEN/>
      </TextContent>
    </Boxed>
  </Layout>
}

export default PrivacyPolicy;